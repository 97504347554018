import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubsciberService {
  private SUBSCRIBER_CREATE = `store/smartvalues/myquery/execute/create_subscriber`;
  private SUBSCRIBER_EDIT = `store/smartvalues/myquery/execute/edit_subscriber`;
  private GET_SUBSCRIBER_LIST = `store/smartvalues/myquery/execute/get_subscriberlist`;
  private GET_POUCH_SUBSCRIBER_RETURNS = `store/smartvalues/myquery/execute/get_pouch_subscriber_returns`;
  private GET_POUCH_SUBSCRIBERS_TOTALRETURNS = `store/smartvalues/myquery/execute/get_pouch_subscribers_totalreturns`;
  private UNMAP_SUBSCRIBER_TO_POUCH = `store/smartvalues/myquery/execute/unmap_subscriber_to_pouch`;
  //! replacing new
  // private MAP_SUBSCRIBER_TO_POUCH = `store/smartvalues/myquery/execute/map_subscriber_to_pouch`;
  private MAP_SUBSCRIBER_TO_POUCH = `smartvalues/func/map_subscriber_to_pouch/`;
  private MAP_SUBSCRIBER_TO_POUCH_NEW = `smartvalues/func/map_subscriber_to_pouch/`;
  private EDIT_MAP_SUBSCRIBER_TO_POUCH = `store/smartvalues/myquery/execute/edit_map_subscriber_to_pouch`;
  private MAP_SUBSCRIBER_TO_POUCH_REBALANCE = `mapsubscribertopouch`;
  private CHANGE_INVESTMENT = `smartvalues/func/change_subscriber_investment_inpouch/`;
  private GET_POUCH_SUBSCRIBER_INVESTMENT = `store/smartvalues/myquery/execute/get_pouch_subscriber_investments`;
  private FETCH_KYC_DOCS = `store/smartvalues/myquery/execute/fetch_kycdocs`;
  private UPLOAD_KYC_FILE = `files/smartvalues/kyc_docs/upload`;
  private SAVE_KYC_DOCS = `store/smartvalues/myquery/execute/save_kyc_doc`;
  private GET_POUCH_SUBSCRIBER = 'store/smartvalues/myquery/execute/get_pouch_subscribers';
  private Save_Subscriber_investment = `smartvalues/func/save_sub_investment`;
  private UPDATE_AMOUNT = 'store/smartvalues/myquery/execute/get_new_subscription_amount';
  private ASSIGN_POUCH = 'store/smartvalues/myquery/execute/assign_pouch';
  private SMARTBETA_FEE = 'store/smartvalues/myquery/execute/get_smartbeta_fees';
  private SAVE_SMARTBETA_FEE = 'store/smartvalues/myquery/execute/save_smartbeta_fees';
  private DELETE_SMARTBETA_FEE = 'store/smartvalues/myquery/execute/delete_smartbeta_fees';

  constructor(private http: HttpClient) { }

  intialSubIdKycDocs: any;
  holdStockLength: any = undefined;
  subscriberCreate(data: any) {
    return this.http.post(environment.baseUrl + this.SUBSCRIBER_CREATE, data);
  }

  editSubscriber(data: any) {
    return this.http.post(environment.baseUrl + this.SUBSCRIBER_EDIT, data);
  }

  public getSubscriberList(data: any) {
    return this.http.post(environment.baseUrl + this.GET_SUBSCRIBER_LIST, data);
  }

  getPouchSubscriberReturns(data: any) {
    return this.http.post(environment.baseUrl + this.GET_POUCH_SUBSCRIBER_RETURNS, data);
  }

  getPouchSubscribersTotalReturns(data: any) {
    return this.http.post(environment.baseUrl + this.GET_POUCH_SUBSCRIBERS_TOTALRETURNS, data);
  }

  unmapSubscriberToPouch(data: any) {
    return this.http.post(environment.baseUrl + this.UNMAP_SUBSCRIBER_TO_POUCH, data);
  }
  //! replacing new routes
  // mapSubscriberToPouch(data: any) {
  //   return this.http.post(
  //     environment.baseUrl + this.MAP_SUBSCRIBER_TO_POUCH,
  //     data
  //   );
  // }

  mapSubscriberToPouch(data: any) {
    if (data.end_date === undefined) {
      data.end_date = data?.subscriberObj?.[0]?.end_date
    }
    try {
      data.end_date = data.end_date?.split('T')[0];
      data.docsi[0].from_date = data.docsi[0].from_date?.split('T')[0];
      data.subscriberObj[0].end_date = data.subscriberObj[0].end_date?.split('T')[0];
      data.subscriberObj[0].start_date = data.subscriberObj[0].start_date?.split('T')[0];
      data.subscription[0].start_date = data.subscription[0].start_date?.split('T')[0];
      data.subscription[0].end_date = data.subscription[0].end_date?.split('T')[0];


      return this.http.post(environment.baseUrl_8083 + this.MAP_SUBSCRIBER_TO_POUCH, data);

    } catch (e) {
      return this.http.post(environment.baseUrl_8083 + this.MAP_SUBSCRIBER_TO_POUCH, data);

    }
  }
  mapSubscriberToPouchRenew(data: any) {
    if (data.end_date === undefined) {
      data.end_date = data?.subscriberObj?.[0]?.end_date
    }
    try {
      data.end_date = data.end_date?.split('T')[0];
      data.docsi[0].from_date = data.docsi[0].from_date?.split('T')[0];
      data.subscriberObj[0].end_date = data.subscriberObj[0].end_date?.split('T')[0];
      data.subscriberObj[0].start_date = data.subscriberObj[0].start_date?.split('T')[0];
      data.subscription[0].start_date = data.subscription[0].start_date?.split('T')[0];
      data.subscription[0].end_date = data.subscription[0].end_date?.split('T')[0];


      return this.http.post(environment.baseUrl_8083 + this.MAP_SUBSCRIBER_TO_POUCH_NEW, data);

    } catch (e) {
      return this.http.post(environment.baseUrl_8083 + this.MAP_SUBSCRIBER_TO_POUCH_NEW, data);

    }

  }

  editMapSubscriberToPouch(data: any) {
    return this.http.post(environment.baseUrl + this.EDIT_MAP_SUBSCRIBER_TO_POUCH, data);
  }

  mapSubscriberToPouchRebalance(data: any) {
    return this.http.post(environment.baseUrl_8081 + this.MAP_SUBSCRIBER_TO_POUCH_REBALANCE, data);
  }

  changeInvestment(data: any) {
    return this.http.post(environment.baseUrl_8083 + this.CHANGE_INVESTMENT, data);
  }

  getPouchSubscriberInvestment(data: any) {
    return this.http.post(environment.baseUrl + this.GET_POUCH_SUBSCRIBER_INVESTMENT, data);
  }
  fetchKyc_docs(data: any) {
    return this.http.post(environment.baseUrl + this.FETCH_KYC_DOCS, data);
  }
  uploadFile(data: any) {
    return this.http.post(environment.baseUrl_8082 + this.UPLOAD_KYC_FILE, data);
  }
  saveKyc_docs(data: any) {
    return this.http.post(environment.baseUrl + this.SAVE_KYC_DOCS, data);
  }
  get_smartbeta_subscriberlist(data: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/get_smartbeta_subscriberlist',
      data
    );
  }
  fetchRiskProfile(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/fetch_sub_riskprofile',
      payload
    );
  }
  fetchDocs(payload: any) {
    return this.http.post(environment.baseUrl_8083 + 'smartvalues/func/fetch_sub_doc/', payload);
  }
  register_smartbeta_subscriber(payload: any) {
    return this.http.post(
      environment.baseUrl_8083 + 'smartvalues/func/register_smartbeta_subscriber/',
      payload
    );
  }
  get_sb_registration_link(payload: any) {
    return this.http.post(
      environment.baseUrl_8083 + 'smartvalues/route/get_sb_registration_link/',
      payload
    );
  }
  get_pouch_subscribers(payload: any) {
    return this.http.post(environment.baseUrl + this.GET_POUCH_SUBSCRIBER, payload);
  }
  getSubscriberpouch(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/get_subscriber_pouches',
      payload
    );
  }
  postSaveSubscriberInvestment(data: any) {
    return this.http.post(environment.baseUrl_8083 + this.Save_Subscriber_investment, data);
  }
  postUpdateAmount(data: any) {
    return this.http
      .post(environment.baseUrl + this.UPDATE_AMOUNT, data)
      .pipe(map((res: any) => res[0].Results[0]));
  }
  intiatePaymentGatewaysvfTopUp(body: any) {
    return this.http.post(
      environment.baseUrl_8083 + `smartvalues/func/initiatetopuppaymenttxn`,
      body
    );
  }
  assignPouch(data: any) {
    return this.http.post(environment.baseUrl + this.ASSIGN_POUCH, data);
  }
  getSmartBetaListing(data: any) {
    return this.http.post(environment.baseUrl + this.SMARTBETA_FEE, data);
  }
  saveSmartBeta(data: any) {
    return this.http.post(environment.baseUrl + this.SAVE_SMARTBETA_FEE, data);
  }
  deleteSmartBeta(data: any) {
    return this.http.post(environment.baseUrl + this.DELETE_SMARTBETA_FEE, data);

  }
}
