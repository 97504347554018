import { PercentPipe } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  FormBuilder
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { cloneDeep } from 'lodash';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { FileHandle } from 'src/app/common/interfaces/file-handle';
import { SharedService } from 'src/app/common/services/shared.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import { NotificationService } from 'src/app/services/notification.services';

import { Load, AddEdit, EmptyStateLoad } from '../../../store/actions/pouch.action';
import { PouchState, selectPouchState } from '../../../store/pouch.state';

@Component({
  selector: 'app-pouch-details',
  templateUrl: './pouch-details.component.html',
  styleUrls: ['./pouch-details.component.scss']
})
export class PouchDetailsComponent implements OnInit, OnChanges {
  displayedColumns: string[] = ['stock_name', 'stock_wt', 'actions'];
  dataSource: any = [];
  benchMarkOptions: any;
  stopSubscription: boolean = false;
  @Input() public: any;
  @Input() minInvestment: number;
  frequency_review: any = [
    {
      name: 'DAILY'
    },
    {
      name: 'WEEKLY'
    },
    {
      name: 'MONTHLY'
    },
    {
      name: 'QUARTERLY'
    }
  ];

  risk_profiles: any = [
    {
      name: 'HIGH'
    },
    {
      name: 'MODERATE'
    },
    {
      name: 'LOW'
    }
  ];
  @Input() pouchId: any;
  pouchState: any;
  isPublicChecked: boolean = false;
  getState: Observable<any>;
  pouchDetailForm: FormGroup;
  subscriptionMonthlyForm: FormGroup;
  subscriptionQuarterlyForm: FormGroup;
  subscriptionHalfYearlyForm: FormGroup;
  todayDate: Date;
  canEditWt: boolean;
  weight: any;
  uploadedFile: FileHandle;
  pouchTimeframeDetail: any;
  uploadedDate: any;
  isDisabled: boolean;
  isHideChecked: boolean = false;

  holdStocks: any[] = [];

  isItermediary: boolean = false;
  INTERMEDIARY: string = 'INTERMEDIARY';
  logginUserRole: any;

  subscription: any = [];
  monthlySub: any = '';
  quarterlySub: any = '';
  halfYearlySub: any = '';
  subscribtionError: string = '';
  constructor(
    private store: Store<PouchState>,
    public dialog: MatDialog,
    private pouchService: PouchService,
    private router: Router,
    private authService: AuthService,
    private sharedService: SharedService,
    private notifyService: NotificationService,
    private fb: FormBuilder
  ) {
    this.todayDate = new Date();
    this.getState = this.store.select(selectPouchState);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.pouchDetailForm?.controls['minimum_investment']) {
      this.pouchDetailForm?.controls['minimum_investment'].setValue(
        changes?.minInvestment?.currentValue.toFixed(2)
      );
    }

    this.benchMarkOptions = this.pouchService
      .getUniverseListNew({
        universe_type: 'SYSTEM'
      })
      .pipe(
        map((ele: any) => {
          return ele[0].universes;
        })
      );
  }

  ngOnInit(): void {
    this.isItermediary = false;
    this.authService.onUserUpdate.subscribe((data) => {
      this.logginUserRole = data.attributes.role;
    });

    if (!this.logginUserRole) {
      const storageUser: any = localStorage.getItem('user');
      const user = JSON.parse(storageUser);
      this.logginUserRole = user.attributes.role;
      if (this.logginUserRole === 'ria' || this.logginUserRole === 'partner') {
        this.isItermediary = true;
      }
    }
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    this.createTimeframeForm();
    this.createSubscriptionForm();
    if (!this.pouchId) {
      this.getState.subscribe((state) => {
        this.pouchState = state;
        if (this.pouchState.pouch) {
          this.pouchTimeframeDetail = this.pouchState.pouch;

          this.dataSource = cloneDeep(this.pouchState.pouch.public___pouch_stocks);
          
        }
      });
      if (!this.pouchState) {
        this.store.dispatch(new EmptyStateLoad({}));
      }
    } else {
      this.pouchService.getPouchList({ current_date: this.uploadedDate.split('T')[0] }).subscribe(
        (res: any) => {
          if (res) {
            let editedPouch = res[0].pouches.find((x: any) => x.id == this.pouchId);
            if (editedPouch) {
              this.isDisabled = true;
              

              this.stopSubscription = editedPouch.stop_sub;
              this.setTimeframeForm(editedPouch);
              let data = {
                pouch_id: this.pouchId,
                stock_price_date: this.uploadedDate.split('T')[0]
              };
              this.pouchService.getPouchStocksReturns(data).subscribe((res: any) => {
                res[0].Results[0].return_data.pouch_stocks_returns.map((element: any) => {
                  if (element['stock_wt'] > 0) {
                    this.holdStocks.push({
                      stock_name: element['stock_name'],
                      stock_wt: element['stock_wt']
                    });
                  }
                });
                this.dataSource = this.holdStocks;
                
              });
            }
          }
        },
        (e) => {
          this.notifyService.showError('', e.error.error.message);
        }
      );
    }
  }

  createTimeframeForm() {
    this.pouchDetailForm = this.fb.group(
      {
        pouch_name: new FormControl('', { validators: [Validators.required] }),
        end_date: new FormControl(new Date(), {
          validators: [Validators.required]
        }),
        minimum_investment: new FormControl(
          { value: this.minInvestment.toFixed(2), disabled: this.isDisabled },
          { validators: [] }
        ),
        investment_strategy: new FormControl('', {
          validators: [Validators.required]
        }),
        risk_profile: new FormControl('', {
          validators: [Validators.required]
        }),
        description: new FormControl('', { validators: [Validators.required] }),
        benchmarkIndex: new FormControl('', {
          validators: [Validators.required]
        }),
        month_percent: new FormControl(''),
        month_val: new FormControl(''),
        month_type: new FormControl(''),
        quarter_percent: new FormControl(''),
        quarter_val: new FormControl(''),
        quarter_type: new FormControl(''),
        half_percent: new FormControl(''),
        half_val: new FormControl(''),
        half_type: new FormControl(''),
        month_agg: new FormControl(''),
        quarter_agg: new FormControl(''),
        half_agg: new FormControl('')
      },
      {
        validator: SubscriptionValidator(
          'month_percent',
          'month_val',
          'month_type',
          'quarter_percent',
          'quarter_val',
          'quarter_type',
          'half_percent',
          'half_val',
          'half_type'
        )
      }
    );
  }

  setTimeframeForm(pouchData: any) {
    this.isPublicChecked = pouchData.is_public
    this.isHideChecked = pouchData.is_hidden

    this.pouchDetailForm.patchValue({
      pouch_name: pouchData.name,
      // end_date : pouchData.end_date,
      // review_freq_day : pouchData.review_freq_day,
      //  minimum_investment: pouchData.minimum_investment,
      investment_strategy: pouchData.investment_strategy,
      // review_freq : pouchData.review_freq,
      risk_profile: pouchData.risk_profile,
      description: pouchData.description,
      monthly_subscriptions: pouchData.monthly_subscriptions,
      annual_subscriptions: pouchData.annual_subscriptions,
      benchmarkIndex: pouchData.benchmark_index
    });
    this.subscription = pouchData.public___pouch_subscriptions.map((subscription: any) => {
      const { rowIndex, subscription_desc, pouch_id, ...rest } = subscription;
      return rest;
    });
    pouchData.public___pouch_subscriptions.forEach((subscription: any) => {
      if (subscription.subscription_freq === 'MONTHLY') {
        let month = 1;
        let subscribtionValue =
          Number(this.pouchDetailForm.controls['minimum_investment'].value) *
          (Number(subscription.subscription_perc) / 100 / 12) *
          month;

        this.pouchDetailForm.patchValue({
          month_percent: subscription.subscription_perc,
          month_val: subscription.subscription_amount,
          month_type: subscription.subscription_type,
          month_agg: subscription.agg_text
        });
        this.monthlySub = this.getSubValue(
          subscription.subscription_type,
          subscribtionValue,
          subscription.subscription_amount
        ).toFixed(2);
      } else if (subscription.subscription_freq === 'QUARTERLY') {
        let month = 3;
        let subscribtionValue =
          Number(this.pouchDetailForm.controls['minimum_investment'].value) *
          (Number(subscription.subscription_perc) / 100 / 12) *
          month;
        this.pouchDetailForm.patchValue({
          quarter_percent: subscription.subscription_perc,
          quarter_val: subscription.subscription_amount,
          quarter_type: subscription.subscription_type,
          quarter_agg: subscription.agg_text
        });
        this.quarterlySub = this.getSubValue(
          subscription.subscription_type,
          subscribtionValue,
          subscription.subscription_amount
        ).toFixed(2);
      } else if (subscription.subscription_freq === 'HALF-YEARLY') {
        let month = 6;
        let subscribtionValue =
          Number(this.pouchDetailForm.controls['minimum_investment'].value) *
          (Number(subscription.subscription_perc) / 100 / 12) *
          month;
        this.pouchDetailForm.patchValue({
          half_percent: subscription.subscription_perc,
          half_val: subscription.subscription_amount,
          half_type: subscription.subscription_type,
          half_agg: subscription.agg_text
        });
        this.halfYearlySub = this.getSubValue(
          subscription.subscription_type,
          subscribtionValue,
          subscription.subscription_amount
        ).toFixed(2);
      }
    });
  }

  createSubscriptionForm() {
    this.subscription.push(
      {
        subscription_freq: 'MONTHLY'
      },
      {
        subscription_freq: 'QUARTERLY'
      },
      {
        subscription_freq: 'HALF-YEARLY'
      }
    );
  }

  savePouch(event: any) {
    this.subscribtionError = '';
    if (this.logginUserRole !== 'partner') {
      if (!this.monthlySub && !this.quarterlySub && !this.halfYearlySub) {
        this.subscribtionError = 'Please select atleast one plan';
        return;
      }
    }

    let endDate = this.pouchDetailForm.controls['end_date'].value;
    // endDate = endDate.setDate(endDate.getDate() + 1);
    if (this.pouchDetailForm.valid) {
      let pouchData = {
        icon_base64: '',
        pouch_name: this.pouchDetailForm.controls['pouch_name'].value,
        // end_date: new Date(endDate).toISOString(),
        // minimum_investment: this.pouchDetailForm.controls['minimum_investment'].value,
        investment_strategy: this.pouchDetailForm.controls['investment_strategy'].value,
        risk_profile: this.pouchDetailForm.controls['risk_profile'].value,
        description: this.pouchDetailForm.controls['description'].value,
        is_public: this.isPublicChecked,
        is_hidden: this.isHideChecked,
        benchmark_index: this.pouchDetailForm.value.benchmarkIndex
      };

      //statePouch.pouch.public___pouch_stocks = this.dataSource

      // var wtTotal = this.dataSource.reduce(function(prev :any, cur : any) {
      //   return prev + Number(cur.stock_wt);
      // }, 0);
      // if(Math.round(wtTotal * 100) !== 100 ){

      //   const dialog : any = this.dialog.open(CommonDialogComponent,
      //                             {data : {message : `Weightage should be equal to 100. Total weightage is ${wtTotal}`, title : 'Validation Error', status : 'Fail'}});
      //   setTimeout(() => {
      //     dialog.close();
      //   }, 1000);
      //   return;
      // }

      // let pouchStocks : any = [];
      // for(let item of this.dataSource){
      //     item.invested_amount = this.pouchDetailForm.controls['minimum_investment'].value * item.stock_wt ;
      //     pouchStocks.push(item);
      // }
      // statePouch.pouch.public___pouch_stocks = pouchStocks;

      this.pouchService
        .editPouch({
          pouch_id: this.isDisabled ? this.pouchId : this.pouchState?.pouch?.pouch_id,
          docs: { ...pouchData, stop_sub: this.stopSubscription ? 'Y' : 'N' },
          subscriptions: this.createSubscriptionDataBody()
        })
        .subscribe(
          (res: any) => {
            if (res.length >= 0 && res[0].pouch && res[0].pouch.err == '-') {
              const dialog: any = this.dialog.open(CommonDialogComponent, {
                data: {
                  message: 'Pouch saved successfully.',
                  title: 'Success',
                  status: 'Success'
                }
              });

              const body = {
                sort: -13,
                current_date: this.uploadedDate.split('T')[0]
              };
              this.store.dispatch(new Load(body));
              setTimeout(() => {
                dialog.close();
                this.router.navigateByUrl('investment');
              }, 1000);
            }
          },
          (e) => {
            this.notifyService.showError('', e.error.error.message);
          }
        );
      this.pouchDetailForm.controls['end_date'].value.setDate(new Date(endDate).getDate() - 1);
    }
  }

  roundOfWeightage(value: any) {
    return Math.round(value * 100);
  }

  onCancelClick() {
    this.router.navigateByUrl('investment');
  }

  updateRowData(event: any, stock: any) {
    let objIndex = this.dataSource.findIndex((value: any) => value.stock_code == stock.stock_code);
    if (objIndex > -1) {
      this.dataSource[objIndex].stock_wt = event.target.value;
    }
    this.canEditWt = !this.canEditWt;
  }

  fileDropped(file: any) {
    this.uploadedFile = file;
  }

  createSubscriptionDataBody() {
    let filteredArr: any = [];   
    
    for (let ele of this.subscription) {
      ele['pouch_id'] = this.pouchId;
      if (
       ( ele.subscription_perc !== undefined || ele.subscription_perc !== null)  &&
        ele.subscription_amount &&
        ele.subscription_freq &&
        ele.subscription_type
      ) {
        filteredArr.push(ele);
      }
    }
  let new_filteredArr = filteredArr.map(({ agg_text_sub, ...rest }:any) => rest);

    return new_filteredArr;
  }

  sub_percentage(freq: string, event: any) {
    let selectedSub = this.subscription.find((ele: any) => ele.subscription_freq === freq);
    if(!selectedSub){
      let data = {subscription_perc:event.target.value,subscription_freq:freq}
      selectedSub = data
      this.subscription.push(data)
    }
    selectedSub['subscription_perc'] = event.target.value;
    if (selectedSub['subscription_perc'] === '') {
      delete selectedSub['subscription_perc'];
    }
    this.calculateSubscribtionValue(freq);
  }
  aggInput(freq: string, event: any) {
    
    let selectedSub = this.subscription.find((ele: any) => ele.subscription_freq === freq);
    selectedSub['agg_text'] = event.target.value;
    if (selectedSub['agg_text'] === '') {
      delete selectedSub['agg_text'];
    }
  }
  sub_amount(freq: string, event: any) {
    let selectedSub = this.subscription.find((ele: any) => ele.subscription_freq === freq);
    selectedSub['subscription_amount'] = event.target.value;
    if (selectedSub['subscription_amount'] === '') {
      delete selectedSub['subscription_amount'];
    }
    this.calculateSubscribtionValue(freq);
  }

  sub_type(freq: string, event: any) {
    let selectedSub = this.subscription.find((ele: any) => ele.subscription_freq === freq);

    selectedSub['subscription_type'] = event.value;
    this.calculateSubscribtionValue(freq);
  }

  calculateSubscribtionValue(freq: any) {
    let percent: any;
    let amount: any;
    let type: any;
    let selectedSub = this.subscription.find((ele: any) => ele.subscription_freq === freq);
    if (selectedSub) {
      percent = selectedSub['subscription_perc'];
      amount = selectedSub['subscription_amount'];
      type = selectedSub['subscription_type'];
    }
    if (freq && percent && amount && type) {
      let month = freq == 'MONTHLY' ? 1 : freq == 'QUARTERLY' ? 3 : freq == 'HALF-YEARLY' ? 6 : 0;
      let subscribtionValue =
        Number(this.pouchDetailForm.controls['minimum_investment'].value) *
        (Number(percent) / 100 / 12) *
        month;
      if (freq == 'MONTHLY') {
        this.monthlySub = this.getSubValue(type, subscribtionValue, amount).toFixed(2);
      } else if (freq == 'QUARTERLY') {
        this.quarterlySub = this.getSubValue(type, subscribtionValue, amount).toFixed(2);
      } else if (freq == 'HALF-YEARLY') {
        this.halfYearlySub = this.getSubValue(type, subscribtionValue, amount).toFixed(2);
      }
    } else {
      if (freq == 'MONTHLY') {
        this.monthlySub = '';
      } else if (freq == 'QUARTERLY') {
        this.quarterlySub = '';
      } else if (freq == 'HALF-YEARLY') {
        this.halfYearlySub = '';
      }
    }
  }

  getSubValue(type: string, calcValue: any, value: any) {
    calcValue = Number(calcValue);
    value = Number(value);
    if (type == 'MAX') {
      return calcValue > value ? calcValue : value;
    } else if (type == 'MIN') {
      return calcValue < value ? calcValue : value;
    } else if (type == 'BOTH') {
      return calcValue + value;
    }
  }

  togglePublic(event: any) {
    this.isPublicChecked = event.checked;
  }

  toggleHide(event: any) {
    this.isHideChecked = event.checked;
  }
  checkDiasble(type: any) {
    if (type === 'public') {
      if (this.isHideChecked) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.isPublicChecked) {
        return true;
      } else {
        return false;
      }
    }
  }
}

export function SubscriptionValidator(
  percent: string,
  value: string,
  type: string,
  quarter_percent: string,
  quarter_val: string,
  quarter_type: string,
  half_percent: string,
  half_val: string,
  half_type: string
) {
  return (formGroup: FormGroup) => {
    const percentControl = formGroup.controls[percent];
    const valueControl = formGroup.controls[value];
    const typeControl = formGroup.controls[type];
    const qpercentControl = formGroup.controls[quarter_percent];
    const qvalueControl = formGroup.controls[quarter_val];
    const qtypeControl = formGroup.controls[quarter_type];
    const hpercentControl = formGroup.controls[half_percent];
    const hvalueControl = formGroup.controls[half_val];
    const htypeControl = formGroup.controls[half_type];
    if (percentControl.value || valueControl.value || typeControl.value) {
      if (!percentControl.value && percentControl.value !== 0) {
        percentControl.setErrors({ isValid: true });
      } else {
        percentControl.setErrors(null);
      }
      if (!valueControl.value && valueControl.value !== 0) {
        valueControl.setErrors({ isValid: true });
      } else {
        valueControl.setErrors(null);
      }
      if (!typeControl.value && typeControl.value !== 0) {
        typeControl.setErrors({ isValid: true });
      } else {
        typeControl.setErrors(null);
      }
    } else {
      percentControl.setErrors(null);
      valueControl.setErrors(null);
      typeControl.setErrors(null);
    }

    // Quarterly subscription validation
    if (qpercentControl.value || qvalueControl.value || qtypeControl.value) {
      if (!qpercentControl.value && qpercentControl.value !== 0) {
        qpercentControl.setErrors({ isValid: true });
      } else {
        qpercentControl.setErrors(null);
      }
      if (!qvalueControl.value && qvalueControl.value !== 0) {
        qvalueControl.setErrors({ isValid: true });
      } else {
        qvalueControl.setErrors(null);
      }
      if (!qtypeControl.value && qtypeControl.value !== 0) {
        qtypeControl.setErrors({ isValid: true });
      } else {
        qtypeControl.setErrors(null);
      }
    } else {
      qpercentControl.setErrors(null);
      qvalueControl.setErrors(null);
      qtypeControl.setErrors(null);
    }

    // Half-yearly subscription validation
    if (hpercentControl.value || hvalueControl.value || htypeControl.value) {
      if (!hpercentControl.value && hpercentControl.value !== 0) {
        hpercentControl.setErrors({ isValid: true });
      } else {
        hpercentControl.setErrors(null);
      }
      if (!hvalueControl.value && hvalueControl.value !== 0) {
        hvalueControl.setErrors({ isValid: true });
      } else {
        hvalueControl.setErrors(null);
      }
      if (!htypeControl.value && htypeControl.value !== 0) {
        htypeControl.setErrors({ isValid: true });
      } else {
        htypeControl.setErrors(null);
      }
    } else {
      hpercentControl.setErrors(null);
      hvalueControl.setErrors(null);
      htypeControl.setErrors(null);
    }

    return;
  };
}
